/**
 * @license
 * MyFonts Webfont Build ID 3635937, 2018-09-06T02:48:33-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FuturaPT-Light by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/futura-light/
 *
 * Webfont: FuturaPT-Book by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/futura-book/
 *
 * Webfont: FuturaPT-Medium by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/futura-medium/
 *
 * Webfont: FuturaPT-Demi by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/futura-demi/
 *
 * Webfont: FuturaPT-Heavy by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/heavy/
 *
 * Webfont: FuturaPT-Bold by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/bold/
 *
 * Webfont: FuturaPT-ExtraBold by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/extra-bold/
 *
 * Webfont: FuturaPT-LightObl by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/futura-light-italic/
 *
 * Webfont: FuturaPT-BookObl by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/italic/
 *
 * Webfont: FuturaPT-MediumObl by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/futura-medium-italic/
 *
 * Webfont: FuturaPT-DemiObl by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/futura-demi-italic/
 *
 * Webfont: FuturaPT-HeavyObl by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/heavy-obl/
 *
 * Webfont: FuturaPT-BoldObl by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/bold-obl/
 *
 * Webfont: FuturaPT-ExtraBoldObl by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/futura-book/extra-bold-obl/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3635937
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright &#x00A9; 1995 ParaGraph Intl., &#x00A9; 1998 ParaType Inc., ParaType Ltd. All rights reserved.
 *
 * © 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/377ae1");


@font-face {font-family: 'FuturaStd';font-weight: 400; src: url('webfonts/377AE1_1_0.eot');src: url('webfonts/377AE1_1_0.eot?#iefix') format('embedded-opentype'),url('webfonts/377AE1_1_0.woff2') format('woff2'),url('webfonts/377AE1_1_0.woff') format('woff'),url('webfonts/377AE1_1_0.ttf') format('truetype'),url('webfonts/377AE1_1_0.svg#wf') format('svg');}


@font-face {font-family: 'FuturaStd';font-weight: 600; src: url('webfonts/377AE1_2_0.eot');src: url('webfonts/377AE1_2_0.eot?#iefix') format('embedded-opentype'),url('webfonts/377AE1_2_0.woff2') format('woff2'),url('webfonts/377AE1_2_0.woff') format('woff'),url('webfonts/377AE1_2_0.ttf') format('truetype'),url('webfonts/377AE1_2_0.svg#wf') format('svg');}

@font-face {font-family: 'FuturaStd'; font-weight: 800; src: url('webfonts/377AE1_4_0.eot');src: url('webfonts/377AE1_4_0.eot?#iefix') format('embedded-opentype'),url('webfonts/377AE1_4_0.woff2') format('woff2'),url('webfonts/377AE1_4_0.woff') format('woff'),url('webfonts/377AE1_4_0.ttf') format('truetype'),url('webfonts/377AE1_4_0.svg#wf') format('svg');}
