main {
  position: relative;
}

main[data-layout="app"] > *:first-child {
  padding-bottom: 96px;
}

a {
  color: inherit;
  text-decoration: none;
}
